

const services = [
    {
        title: "REGISTER WITH US",
        link: "register"
    },
    {
        title: "REFERRALS/ADMIN QUERIES",
    },
    {
        title: "PRESCRIPTIONS",
        link:"prescriptions"
    },
    {
        title: "SELF-REFER",
        link: "self-refer"
    },
    {
        title: "TELL US HOW WE ARE DOING",
        link: "feedback-form"
    },
    {
        title: "PATIENT PARTICIPATION GROUP",
        link: "patient-participation-group"
    },

];

const clinicians = [
    {
        name: "Dr Katherine Barnes",
        title: "Partner",
        bio: ["MBBS(London) 1989", "FRACGP dip..OSB.RACOG"],
        speciality: "Women's health, contraception, and children"
    },
    {
        name: "ANP Jo Flint"
    },
    {
        name: "Dr Thomas Bailey",
        title: "Partner",
        bio: ["BA(Oxon) MBBS(London) 2003", "MRCGP MRCP DCH DFSRH"],
        speciality : "diabetes, muscle & joint problems, dizziness. Also runs the fresh start drug & alcohol clinic"
    },
    {
        name: "Dr Panduka Ubayasiri",
        title: "Partner",
        bio: ["MBBS(London) 2001", "MRCGP MRCP DCH DFSRH", "PGCertEd"],
        speciality: "Medical  Education and Ophthalmology"
    },
    {
        name: "Dr Chris Allen",
        title: "Partner",
        bio: ["MBBS MRCGP DPD"],
        speciality: "Chronic Disease Management, Dermatology and Skin Surgery Lead GP for Feedback and Complaints"
    },
    {
        name: "Dr Paul Hallett",
        title: "Partner",
        bio: ["BMBS (Nottingham 2007) BMedSci MSc Sports", "Exercise Medicine DRCOG Diploma in Clinical Leadership"],
        speciality: "sports medicine, injuries and joint injection Also sees private patients. Please email swlccg.heathbridgeadmin@nhs.net with the subject private patient request and we will be able to arrange an appointment for you."
    },
    {
        name: "Dr Premila Allen"
    },
    {
        name: "Dr Thomas Koczian"
    },
    {
        name: "Dr Helen Lamplough"
    },
    {
        name: "Dr Samual Lazarus"
    },
    {
        name: "Dr Conrad Lippold"
    },
    {
        name: "Dr Victoria Poole"
    },
    {
        name: "Dr Olga Smetanina"
    },
    {
        name: "Dr Anna Sutton"
    },
    {
        name: "Dr Anita Tse"
    },
    {
        name: "Dr Elizabeth Vernon"
    },
    {
        name: "Kelsey Lawson"
    },
    {
        name: "Rowena Price"
    },
    {
        name: "Hilary Hodgson"
    },
    {
        name: "Sarah Jolly"
    },
    {
        name: "Lisa Smellie"
    },
    {
        name: "Lesley Costa"
    },
    {
        name: "Donna Taylor"
    },
    {
        name: "Charlene Watts"
    },
    {
        name: "Shahil Soni"
    },
    {
        name: "Faiza Usama"
    },
    {
        name: "William Bateman"
    },
    {
        name: "Simran Kaurr"
    },
    {
        name: "Kelly Downes"
    },
]

const nonClinicians = [
    {
        name: "Jay Francisco"
    },
    {
        name: "Simona"
    },
    {
        name: "Cedrick"
    },
    {
        name: "Kuan"
    },
    {
        name: "Charlotte"
    },
    {
        name: "Sarah"
    },
    {
        name: "Tracey C."
    },
    {
        name: "Alisa"
    },
    {
        name: "Carmenne"
    },
    {
        name: "Charlotte"
    },
    {
        name: "Chris"
    },
    {
        name: "Danielle"
    },
    {
        name: "Jan"
    },
    {
        name: "Jay B."
    },
    {
        name: "Jaya"
    },
    {
        name: "Johan"
    },
    {
        name: "Melanie"
    },
    {
        name: "Rita"
    },
    {
        name: "Steven"
    },
    {
        name: "Tracey S."
    },
    {
        name: "Venny"
    },

];

const policies = [
    {
        id: 1,
        title: "GDPR and Confidentiality",
        bio: [
            "The Practice takes our responsibilities associated with Data Protection seriously please find below a copy our Privacy Notice which tells you how we handle and use your personal information. In addition to this should you require access to your information we would appreciate if you could complete a copy of the Practice’s application form (below) to access your records and follow the instructions on the form",
            "The practice complies with data protection and access to medical records legislation.",
            "Doctors, nurses and administration staff operate a strict policy of patient confidentiality, and you should feel confident in disclosing any information which is important to your medical care. Information in your medical records may be shared with others directly involved in your care: other members of the practice team, other clinicians, and practice staff for administrative purposes. Information will not be given to anyone else without your consent, except in very rare circumstances, for instance where there are child protection concerns.",
            "The General Data Protection Regulation (GDPR) came into law 25th May 2018.",
            "These changes to the data protection laws do not alter what we use your personal information for.  They will make it easier for you to find out how we use and protect your information.  The fact that the law is changing only builds on what we already do now.  It is to give your personal information the respect and security it deserves.",
            "Your personal information is an important part of our service.  For instance, it lets us provide you with the right health care, including sharing your health information with other health providers such as hospitals.",
            "You do not need to do anything or take any action.  If you would like to find out more, please take a look at our privacy policy here or ask for a copy at reception.",
            "IF YOU HAVE ANY QUESTIONS PLEASE ASK TO SPEAK TO THE PRACTICE MANAGER."
        ],
    },
    {
        id: 2,
        title: "Access to Medical Records",
        bio: [
            "To apply for access to your medical records, write to the Practice Manager, Jay Francisco. You do not need to give a reason for wanting to see them.",
            "If you wish to view your records in advance, an appointment will be arranged.",
        ],
    },
    {
        id: 3,
        title: "Zero Tolerance",
        bio: [
            "We try at all times to deal with patients in a polite and courteous manner. In return we expect that the same is shown to both the staff and doctors at the surgery. Whilst we understand that a visit to the doctors can at times be stressful or worrying we will not tolerate aggression or abuse to either our reception staff or any of the Professional staff at the surgery.",
            "Any patient being threatening or abusive to any member of our team may be removed from our practice list without further warning.",
        ],
    },
    {
        id: 4,
        title: "Financial Disclosure",
        bio: [
            "NHS England require that the net earnings of Doctors engaged in the Practice is publicised, and the required disclosure is shown below. However it should be noted that the prescribed method for calculating earnings is potentially misleading because it takes no account of how much time Doctors spend working in the Practice, and should not be used to form any judgment about GP earnings, nor to make any comparison with any other Practice.",
            "All GP Practices are required to declare the mean earnings (e.g. average pay) for GPs working to deliver NHS services to patients at each Practice. The average pay for GPs working in the practice of The Heathbridge Practice in the last financial year was £67,501 before tax and National Insurance. This is for 6 full time GPs and 7 part time GPs who worked in the practice for more than six months.",
        ],
    },
    {
        id: 5,
        title: "Sick Note",
        bio: [
            "If you have already been issued a Sick Note (Fit Note) for this illness, you may not need to be seen anymore.  Instead you may just need to be reissued an additional Sick Note.   In this case please complete the form below.  We will contact you to let you know when you can collect your Sick / Fit Note or we may contact you to arrange an appointment.",
            "If you have been ill for 7 days or less there is no need to see a GP.  You can complete a Self-Certification form and hand it in to whoever requests it from you.",
            "NOTE: If you are required to provide a doctor’s note (sick note), and self-certification is not enough, we are able to provide this, however please be aware that this will incur a charge."
        ],
    },
    {
        id: 6,
        title: "Complaints Policy",
        bio: [
            "If you are a registered patient you can complain about your own care. You are unable to complain about someone else’s treatment without their written authority.",
            "There are several ways to raise a complaint.",
            [
                "1) By email to swlicb.feedbacktoheathbridge@nhs.net (this is the best way)",
                "2) In writing addressed to Dr Christopher Allen and the Practice Manager",
                "3) Over the phone with one of the managers"
            ],
            "We look to settle complaints as soon as possible.",
            "Your complaint will be acknowledged within three working days by the Patient Liaison Manager, Kuan Peng, with a plan as to how it will be investigated, and a time-frame in which this will be achieved, usually within 21 days from the receipt of your complaint.  If the matter is likely to take longer, we will keep you updated.",
            "The responsible partner for complaints handling is Dr Christopher Allen, if clinical, or the practice manager, if non-clinical, both of whom will oversee the investigation.  Once we have concluded investigations, Dr Allen or the Practice Manager will report back to you.",
            "When looking into a complaint we attempt to see what happened and why, to see if there is something we can learn from this, and make it possible for you to discuss the issue with those involved if you would like to do so. When the investigations are complete we will send you a response letter which will include details of the result of your complaint and also your right to escalate the matter further if you remain dissatisfied with the response",
            [
                "NHS England",
                "PO Box 16738",
                "Redditch",
                "B97 9PT"

            ],
            "Tel:  0300 311 22 33",
            "email: england.contactus@nhs.net",
            "If you need support with your complaint, you can contact the NHS Complaints Advocates on 0300 330 5454 as they are experienced in supporting people with complaints about the NHS. Further information about the advocacy service can be found at www.nhscomplaintsadvocacy.org."
        ],
        links: 
            [
                {
                    title: "Complaints Form",
                    url : "https://c9dc95b2-ce30-4916-b7ea-687330628c40.usrfiles.com/ugd/c9dc95_016bef54054d49739123064cb1aafc98.pdf"
                }
            ],
    },
    {
        id: 7,
        title: "Removal From Practice List",
        bio: [
            "A good patient-doctor relationship, based on mutual respect and trust, is the cornerstone of good patient care. The removal of patients from our list is an exceptional and rare event and is a last resort in an impaired patient-practice relationship. When trust has irretrievably broken down, it is in the patient’s interest, just as much as that of The Surgery, that they should find a new practice. An exception to this is on immediate removal on the grounds of violence e.g. when the Police are involved.",
            [{
                subHeading: "Removing other members of the household"
            }],
            "In rare cases, however, because of the possible need to visit patients at home it may be necessary to terminate responsibility for other members of the family or the entire household. The prospect of visiting patients where a relative who is no longer a patient of the practice by virtue of their unacceptable behaviour resides, or being regularly confronted by the removed patient, may make it too difficult for the practice to continue to look after the whole family. This is particularly likely where the patient has been removed because of violence or threatening behaviour and keeping the other family members could put doctors or their staff at risk.",
        ],
    },
    {
        id: 8,
        title: "Chaperone",
        bio: [
            "You are very welcome to be accompanied by a trusted friend or relative at your consultation if you wish, and this is all the more important if English is not your first language, or if you feel you might not be able to express what you need clearly to the Doctor, Nurse or Clinician.",
            "In additon, for intimate examinations, you will be offered the choice of having another professional clinician present at the examination. This is called chaperoning.",
            "If we can’t allocate a chaperone when you wish one to be there, then we can reschedule your examination to another time",
        ],
    },
    {
        id: 9,
        title: "Consent",
        bio: [
            "We respect our patients rights to privacy and their choice of how they wish to be treated. Consent Sometimes you may wish to stop treatment or make choices in the way you receive your treatment. We also ask require implied or informed consent for sharing any medical information for referrals and GP reports. We also respect data protection act.",
        ],
        links: 
            [
                {
                    title: "Privacy Notice",
                    url : "https://c9dc95b2-ce30-4916-b7ea-687330628c40.usrfiles.com/ugd/c9dc95_2934cffa597041edbca35ed344c52eab.pdf"
                }
            ],
    },
    {
        id: 10,
        title: "Statement Of Purpose",
        bio: [
            ["Our Mission Statement"],
            "Aiming to be the best practice patients could visit, providing an excellent service from the good staff and maintaining high standards in all areas ",
            ["Our Aims and Objectives "],
            "To improve the health, well-being and lives of those we care for and to work in partnership with our patients and staff to provide the best Primary Care services possible working within local and national governance, guidance and regulations.",
            "To provide high quality, safe and friendly professional General Practice services to our patient’s",
            "To focus on prevention of disease by promoting health and wellbeing and offering care and advice to patients",
            "To work in partnership with our patients, their families and carers towards a positive experience and understanding, involving them in decision making about their treatment and care and so actively enabling people to maintain the maximum possible level of independence, choice and control",
            "To be a progressive, learning and developing practice that continually strives to improve on the care we are able to offer patients",
            "To be a training practice helping to train and develop future GP’s, nurse practitioners, nurses, HCA’s and admin staff ",
            "To treat patients as individuals and with the same respect we would want for ourselves or a member of our families, listening and supporting people and enabling them to express their needs and wants and to encourage our patients to communicate with us by joining our Patient Participation Group, talking to us, participating in surveys, and giving feeding back on the services that we offer and the way we offer and the way in which we deliver them",
            "To work closely with other agencies to tackle the causes of, as well as provide the treatment for ill health and where appropriate involve other professionals in the care of our patients. ",
            "To ensure all staff have the competency, motivation and support deliver the required standards of care by ensuring that all members of the team have the right skills and training to carry out their duties competently",
            "To protect and care for our staff offering support to do their jobs and to protect them against abuse. We have a zero tolerance policy of any forms of abuse at the practice ",
            "To provide our patients and staff with an environment which is safe, caring and friendly ",
            "To operate on a financially sound basis. "
        ],
    },

    {
        id: 11,
        title: "Practice Charter",
        bio: [
        ],
        links: [
            {
                title: "Practice Charter",
                url : "https://c9dc95b2-ce30-4916-b7ea-687330628c40.usrfiles.com/ugd/c9dc95_ee5be8010edc426e82feb55042cb73a7.pdf"
            },
        ],
    },
    {
        id: 12,
        title: "Privacy Notice",
        bio: [
        ],
        links: [
            {
                title: "Please find our privacy notice here",
                url : "https://c9dc95b2-ce30-4916-b7ea-687330628c40.usrfiles.com/ugd/c9dc95_2934cffa597041edbca35ed344c52eab.pdf"
            },
        ],
    },
    {
        id: 13,
        title: "DNA (DID NOT ATTEND)",
        bio: [
            ["Background"],
            "Most of our patients know it can sometimes be difficult to get a routine appointment with a GP or Nurse. In the course of events where demand is unpredictable, that cannot easily be remedied.",
            "One thing that makes this more difficult to overcome is the problem of missed routine appointments – DNAs.",
            "Where patients have been declined routine appointments because the consultations are fully booked, it is at best disappointing when one of those booked appointments does not turn up and has not contacted the practice to cancel the appointment so that it can be released for others or telephones so late as to make it impossible to allocate to another patient.",
            "Remember that your DNA is other patient’s denied appointment.",
            ["Heathbridge Practice’s DNA protocol"],
            "A DNA occurs when an appointment is not attended and the patient has not contacted the practice in advance to cancel it or where the cancellation is so late as to make it impossible to allocate that time to another patient who needs treatment.",
            "The practice will code this DNA and this will prompt a retrospective check on the number of DNAs recorded against that person. Whilst we are primarily concerned with our own appointment observance, consideration may also be given to any hospital appointments where we have been notified that a patient has failed to attend. A re-referral on the part of the GP (more GP time) will often be required by the hospital department so that the patient can be recalled.",
            ["First DNA"],
            "Where this is the first occasion the patient may be contacted by the clinician or practice manager. A DNA code will automatically be put recorded in the patient’s notes.",
            ["SUBSEQUENT DNAs"],
            "Where this might be the second or third occasion etc. the clinician will record this in the patient’s notes and alert the practice manager for a warning letter to be sent out.",
            ["WARNING LETTER"],
            "The practice manager will send out a warning letter informing the patient of the number of DNA’s they have had in the last twelve months and that removal from the list is highly likely if it continues.",
            "(Removal from the practice list remains solely at the discretion of the partners. This is normally dependent upon medical circumstances.)",
            "If removal from the list has been agreed, a letter will be sent out to the patient informing them of the reasons behind their removal from the practice list.",
            ["HOW TO AVOID BECOMING A DNA (for patients"],
            "If you cannot attend or no longer need an appointment, please ring us in advance. Mistakes do happen and the practice understands that appointments can be forgotten about or overlooked. In such cases, the practice will take into account the reason given by patients.",
            "Preference, of course, is for the practice to know in advance so we can offer the appointment(s) to other Patients in need.",
            ["Please note: If you cannot attend your appointment, you must cancel AT LEAST an hour before your appointment so that there is ample time to offer it to somebody else. If the reason is not valid for non-cancellation at least an hour before, the practice will make a decision whether to apply a DNA code to the patient’s records."],
            [{
                subHeading: "SHOULD YOU NEED TO CANCEL, HERE’S HOW:"
            }],
            ["A) By telephone"],
            "appointments line 020 8246 4070 (we know this can be busy so to save time, try",
            ["B) By SMS (by reply only)"],
            "If the practice has your most updated mobile number, you will receive a text confirming your appointment details. If you are unable to make this appointment you simply text back CANCEL and this will automatically cancel your appointment. Many patients prefer this method as it is quick and simple. But in order to access this service, the practice must have your most up to date mobile number.",
            ["By the NHS App"],
            "If you have the NHS app, you can simply log in and cancel any future appointment.",
            [{
                subHeading: "What we as a practice are doing to reduce DNAs"
            }],
            "We are reminding our patients what our policy is and we feel that it is important to enforce it robustly. It has been suggested and discussed at practice meetings that Heathbridge practice puts in place a system to improve services for patients.",
            "We are continuously reviewing our emergency appointments and routine appointments availability. Here is what we will endeavour to do to help you not become a DNA:-",
            "· We will always print appointment details for patients who make an appointment face to face at our reception desk. Our appointment cards contain our telephone number should patients need to cancel (even at short notice – at least an hour before).",
            "· If you make an appointment over the telephone, we would suggest that patients record/document the date and time in a way that can be easily accessed – in a diary, on a calendar or for the more technically minded on a mobile phone. Our staff are trained to repeat all appointment details and clarify understanding with patients at the time of making the appointment.",
            ["KEEP IT OR CANCEL IT! SOMEONE ELSE NEEDS THE APPOINTMENT!"],
        ],
    },
];

const listOfOurServices = [
    {
        id: 1,
        title: "Antenatal Check",
        bio: [
            "Confirmation of pregnancy please contact your local pharmacy for pregnancy testing kits",
            "Routine Antenatal Checks (usually at 24, 31, 38 weeks): Please contact our care navigator team to book and our Antenatal clinic is available on Wednesday afternoon.",
            "Pertussis (Whooping Cough): Usually at 24 week check-up.",
            "If you are newly pregnant :",
            "Congratulations! Make sure you are taking Vitamin D & Folic Acid supplements. You can self-refer to the main hospitals e.g. Chelsea & Westminster or Kingston Hospital. Once done, the scan at 12-13 weeks will happen automatically. The midwives will arrange to see you at 9-10 weeks. But here is some info to read beforehand https://www.nhs.uk/pregnancy/",
        ]
    },
    {
        id: 2,
        title: "Blood Test",
        bio: [
            "Blood tests have a wide range of uses and are one of the most common types of medical test.",
            [
            "For example, a blood test can be used to:",
            "Assess your general state of health",
            "Check if you have an infection",
            "See how well certain organs, such as the liver and kidneys, are working",
            "Screen for certain genetic conditions",
            ],
            "Most blood tests only take a few minutes to complete and are carried out at your GP surgery or local hospital by a doctor, nurse or phlebotomist (a specialist in taking blood samples).",
            "How do I get my results ?",
            "Please use NHS APP to access your results. The clinician who has reviewed the results will make a comment about whether any action is required.",
            [
            "Telephone",
            "If you don't have access to the internet or a smart phone, then you can still call the practice and speak to our care navigator team from 10:00 am. We will only contact you about results that require action. Please check your test results according to the timescales below: "
            ],
            "Blood tests, swab tests and stool samples : 7 days",
            "How to obtain my hospital test results ? ",
            "Results are not automatically forwarded to the practice if they have been requested by a hospital clinician. It may not be appropriate for the clinicians at the practice to be interpreting hospital tests. Please call the hospital clinic that organised the test to follow up the results.",

        ]
    },
    {
        id: 3,
        title: "HCA Appointments",
        bio: [
            "Below is a list of services you can book with our healthcare assistants: ",
            "B12",
            "Blood pressure (BP) check",
            "BP 24HR",
            "Blood test",
            "ECG",
            "Health check as new patients",
            "Health check over 40 years old",
            "Pneumonia jab",
            "Smoking cessation",
            "Spirometry"
        ]
    },
    {
        id: 4,
        title: "Interpreter Appointments",
        bio: [
            "If you need an interpreter, please book ahead with the receptionist.",
        ]
    },
    {
        id: 5,
        title: "Joint Injections",
        bio: [
            "Please book an appointment to see one of our Doctors: Dr Christopher Allen, Dr Tom Bailey or Dr Paul Hallett.",
        ]
    },
    {
        id: 6,
        title: "Minor Operations",
        bio: [
            "If you think you need something removed, please click on this link and tell us about and attach some photos: ",
        ],
        links: [
            {   
                title: "Click Here",
                url: "https://florey.accurx.com/p/H85061"
            }
        ]
    },
    {
        id: 7,
        title: "Mole Check",
        bio: [
            "Please book an appointment to see Dr. Chris Allen.",
        ]
    },
    {
        id: 8,
        title: "Nurse Appointments",
        bio: [
            "Below is a list of services you can book with our Nurses: ",
            "Asthma review",
            "Baby jabs",
            "Child registrations under 8 years",
            "Compression Stockings",
            "Depo injections (Contraception & Mental health)",
            "DiabeticReview",
            "Dressings",
            "Routine Pill Check",
            "Shingles",
            "Smear",
            "Stitch removals",
            "Zoladex - Nurse Lisa, Mrs Jo Flint (Nurse Practitioner), Dr Bailey or Dr Ubayasiri"
        ],
        links: [
            {   
                title: "Ear syringing is currently unavailable. Please click on this link to contact with a private team located in putney for this service.",
                url: "https://earwaxremovalclinic.co.uk/ear-clinic-online-bookings/ear-clinic-putney-online-bookings/"
            }
        ]
    },
    {
        id: 9,
        title: "Private fees for Non-NHS services",
        bio: [
            "When your doctor is asked to give medical information about you in the form of a report, letter or certificate, the request kick starts a series of processes.",
            "This takes time and is not always straightforward or simple to complete. Some of the information is not available easily and will mean the doctor has to sort and select the right information for the request.",
            "The doctor also must establish who is funding this work and if it is not part of their NHS work, agree a fee for this.",
            [
                "Surely the work is paid for by the NHS?",
                "Many patients see their doctor as the embodiment of the NHS and all that it provides – free care at the point of delivery. However not all work doctors are asked to do is paid for by the NHS and many GPs are self-employed. "
            ],
            "This means they must cover their time and costs - staff, buildings, heating, lighting, etc - in the same way as any small business. The NHS only pays for NHS work, any work outside of the NHS must be funded by other means and this is why fees are charged.",
            [
                "Why does it take so long?",
                "Your doctor receives large amounts of requests for information for reports.  They can range from requests for information about whether your general health allows you to do something such as work, receive benefits, drive, play sport, attend school, own a house, a firearm, to reports for insurance applications, court or other medicolegal matters. All requests will vary."
            ],
            [
                "What your doctor is signing:",
                "When your doctor signs a certificate or completes a report, it is a condition of remaining on the Medical Register that they only sign what they know to be true. In order to complete even the simplest of forms, they may have to check your entire medical record (some of which may not be accessible on a computer or on site). Carelessness or an inaccurate report can have serious consequences for the doctor with the General Medical Council (the doctors' regulatory body) or even the Police. "
            ],
            [
                "Why does my doctor seem reluctant or say no to this request?",
                "Your doctor is inundated with work. They have to balance their time with treating the sick, keeping their practice afloat and making sure they are doing all of this safely and within their professional duties as a doctor. With certain exceptions written within their contract, doctors do not have to carry out non-NHS work. However, many choose to for the benefit of you and other families they treat. Where a doctor chooses to undertake the work, we advise them to inform and always agree a fee in advance of undertaking work. Should their volume of work prove to be greater or more complex than expected, the doctor will contact you to discuss how to proceed. "
            ],
            [
                "What can I do to help?",
                "Not all documents need a signature by a doctor and can be done by other professionals. Please check the form and accompanying guidance as you may get a quicker response that way.",
                "If you have several forms requiring completion, present them all at once and ask your doctor if he or she is prepared to complete them at the same time to speed up the process. ",
                "Do not expect your GP to process forms overnight. Urgent requests may mean that a doctor has to make special arrangements to process the form quickly, and this will cost more.",
                "Don’t book an appointment with your doctor to complete forms without checking with your doctor’s administrative staff as to whether you need to or not."
            ],
            [
                "CERTIFICATES/ FORMS/ LETTERS From £40:",
                "Proof of Registration letter",
                "Private sicknote/ certificate",
                "BUPA/ AXA etc insurance claim form",
                "Fit to exercise letter",
                "Fitness to fly/ travel letter",
                "Pregnancy Fit to fly letter",
                "Letter for housing/ PIP support",
                "Holiday cancellation letter/ certificate "
            ]

        ]
    },
    {
        id: 10,
        title: "Private Patients",
        bio: [
            "Private consultations with Dr Paul Hallett are available at request.  Please call us or send a message below and one of our team members will be in touch as soon as possible to arrange a suitable date and time for you.",
        ],
        links: [
            {   
                title: "Click Here",
                url: "https://florey.accurx.com/p/H85061"
            }
        ]
    },
];

const otherData = [
    {
        id: 1,
        title: "Financial Disclosure",
        bio: [
            "NHS England require that the net earnings of Doctors engaged in the Practice is publicised, and the required disclosure is shown below. However it should be noted that the prescribed method for calculating earnings is potentially misleading because it takes no account of how much time Doctors spend working in the Practice, and should not be used to form any judgment about GP earnings, nor to make any comparison with any other Practice.",
            "All GP Practices are required to declare the mean earnings (e.g. average pay) for GPs working to deliver NHS services to patients at each Practice. The average pay for GPs working in the practice of The Heathbridge Practice in the last financial year was £67,501 before tax and National Insurance. This is for 6 full time GPs and 7 part time GPs who worked in the practice for more than six months.",
        ]
    },
];

const reviews = [
    {
        id: 1,
        name: 'Alex',
        date: '08 March 2024',
        title: "Fantastic practice",
        stars: 5,
        post: [
            "Excellent practice - I've generally had good experiences here, and often find how lucky I am to be registered here when discussing with friends about their own experiences at other practices. Queries tend to be addressed promptly, and a recent appointment I had has been one of the best I've had since moving to the UK. The GP took time to really understand the patterns of my chronic condition and devise a plan with me to improve how it is managed. I left feeling heard, hopeful, and like I had an understanding of my care. Thank you!",
        ]
    },
    {
        id: 2,
        name: 'Giorgia',
        date: '12 April 2024',
        title: "Fantastic practice with great staff!",
        stars: 5,
        post: [
            "The Heathbridge Practice deserves more than five stars. I reached out as I found a pea-size lump in my right breast and they got me an appointment within days. At the appointment, I struggled to find the lump again, but the doctor was really patient and assured me we could take all the time needed to find it and check it. Once found and inspected, I was referred to the hospital for a scan (thankfully it was just a cyst). Everything was handled professionally and quickly, making me feel reassured and at ease.",
        ]
    },
    {
        id: 3,
        name: 'Douglas Elliott',
        date: '20 December 2023',
        title: "Fabulous service",
        stars: 5,
        post: [
            "Heathbridge’s focus on keeping its patients healthy is very impressive. They are constantly looking to make sure I stay well.Issues are tackled early and many of their actions are preventative. The staff are always sympathetic, engaging, professional and clearly genuinely care. Congratulations and thank you. Simply brilliant!",
        ]
    },
    {
        id: 4,
        name: 'Louise',
        date: '18 December 2023',
        title: "Excellent practice",
        stars: 5,
        post: [
            "I joined Heathbridge practice this year after moving house and recently had twins.",
            "I have been so impressed with the care received compared to my previous GPs and particularly compared to experiences of friends in the area trying to get appointments arranged for their babies.",
            "Online service is so impressive and quick, for example medications needed or physio referral when pregnant.",
            "The GPs and nurse I have met when having postnatal check and appointments for babies have been friendly, professional and very competent. They really give the impression that they care.",
            "I could not recommend this practice enough.",
        ]
    },
];

module.exports = {services, clinicians, nonClinicians, policies, listOfOurServices, otherData, reviews};
